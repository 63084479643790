import React,{useState} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from 'flowbite-react';  // Import Flowbite components

import logo from '../assets/img/logo.png';
import logo2 from '../assets/img/second-logo.png';
import glod from '../assets/img/glod.png';
const Certificate = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
  });
  const [certificateLink, setCertificateLink] = useState(null);
  const [pdfLink, setPdfLink] = useState(null); // Store the PDF link separately
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);  // State to control modal
  const [userExists, setUserExists] = useState(false);  // State to check user existence
  const [alreadyDownloaded, setAlreadyDownloaded] = useState(false); // New state for handling 207 status

  // Handle input change
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Validate form data
  const validateForm = () => {
    const { fullName, email, phone } = formData;
    if (!fullName) {
      toast.error('Please enter your full name.');
      return false;
    }
    if (!email && !phone) {
      toast.error('Please provide either email or phone.');
      return false;
    }
    return true;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/generate-certificate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      // Check for the 207 status
      if (response.status === 207) {
        setAlreadyDownloaded(true); // Set alreadyDownloaded to true for 207 status
        setShowModal(true); // Show the modal
        setLoading(false);
        return;
      }

      // Check if the request was successful
      if (!response.ok) {
        const errorResult = await response.json();
        throw new Error(errorResult.message || 'Request failed');
      }

      const result = await response.json();

      // Set both PNG and PDF URLs
      setCertificateLink(result.pngUrl); // For viewing the PNG
      setPdfLink(result.certificateUrl); // For downloading the PDF
      setUserExists(true); // Set user existence to true
    } catch (error) {
      console.error('Error fetching certificate:', error);
      setUserExists(false); // Set user existence to false on error
      toast.error(error.message);
    } finally {
      setLoading(false);
      setShowModal(true); // Show modal on completion of request
    }
  };

// console.log("modal",Modal.Header,Button)
  return (
    <>
          <ToastContainer
position="top-center"
autoClose={3000}
hideProgressBar={true}
newestOnTop={false}
closeOnClick={false}
rtl={false}
closeButton={false}
pauseOnFocusLoss={false}
draggable={false}
pauseOnHover={false}
theme="colored"
/>

          <header className="m-0 p-0 third-header-bg home-five-header">
        <div className="bg"></div>
        <div className="mb-0 px-4">
            <div className="mb-0 header-top-area t-header-top-area">
                <div className="row">
                    <div className="col-sm-7 d-none d-lg-block">
                        <div className="header-top-login">
                            <ul>
                                
                                <li><a href="tel:9711078793"><i className="fa fa-phone"></i> (+91) 9711078793</a></li>
                                <li><a href="mailto:colorgradeshala@gmail.com"><i className="fa fa-envelope"></i> colorgradeshala@gmail.com</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-5">
                        <div className="d-flex mob-justify-content-end justify-content-end">
                            <div className="header-top-social hidden_sm d-none d-lg-block">
                                <ul>
                                    <li className=''>Follow us :</li>
                                    <li><a target="_blank" href="https://www.instagram.com/colorgradeshala/"><i className="fab fa-instagram"></i></a></li>
                                </ul>
                            </div>
                            <div className="header-top-login">
                                <ul>
                                      <li className="white">Not Yet Registered ?</li>
                                    <li><a href="https://silversparrowproduction.com/colorgradeshala/the-art-of-color-grading/" target="_blank" className="blink_bg"><i className="far fa-edit"></i>Enroll Now</a></li>
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div id="">
            <div className="px-4 pt-0 mt-0 container">
                <div className="row">
                    <div className="col-12">
                        <div className="main-menu menu-style-two">
                       
                                <div className="logo d-block d-lg-none">
                                    <a href="/"><img src={logo} alt="Logo"/></a>
                                  <img className="m-second-logo" src={logo2} alt="Logo"/>
                                </div>
                                <div className="navbar-wrap d-none d-lg-flex justify-center align-center">
                                    <ul className="left ">
                                        <li className="show"><a className='pt-2' href="https://silversparrowproduction.com/about-us/">About Company</a></li>
                                        
                                    </ul> 
                                    <div className="logo">
                                        <a href="/"><img src={logo} alt="Logo"/></a>
                                       
                                    </div>
                                    <ul className="right"> 
                                       
                                        <li className="white ">Powered By : Silver Sparrow Production
                                        </li>
                                    </ul>
                                </div>

                        </div>
                       
                        
                    </div>
                </div>
            </div>
        </div>
        {/* <div className="header-bottom-bg"></div> */}
</header> 
<main className="pt-0 mt-0">
        <section className="h-96 breadcrumb-area breadcrumb-bg">
          <div>
            <div className="row">
              <div className="col-12">
                <div className="breadcrumb-content text-center">
                  <img className="m-second-logo mob-hide margin-bot" src={logo2} alt="Logo" />
                  <h2>
                    DOWNLOAD YOUR <span>CERTIFICATE</span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content-bg blog-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-md-3">
                <div className="pay-now">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <div className="in-between">
                        <label className="margin-bottom">Enter Your Details Below: </label>
                      </div>
                      <input
                        type="text"
                        name="fullName"
                        className="form-control"
                        placeholder="Full Name"
                        value={formData.fullName}
                        onChange={handleChange}
                      />
                      <input
                        type="email"
                        name="email"
                        className="form-control email-input"
                        placeholder="Registered Email Address"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      <div className="in-between">
                        <label>OR </label>
                      </div>
                      <input
                        type="text"
                        name="phone"
                        className="form-control"
                        placeholder="Registered Phone Number"
                        value={formData.phone}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group d-flex justify-content-center">
  <button type="submit" className="btn btn-style-two" disabled={loading}>
    {loading ? 'Please wait...We are verifying your details' : 'Verify your Details'}
  </button>
</div>


                    {/* Display certificate download link */}
                    {/* {certificateLink && ( */}
                      {/* <div className="form-group success-msg">
                        <div className="certificate">
                          <img className="success" src={glod} alt="Success" />{' '}
                          <a href={certificateLink} target="_blank" rel="noopener noreferrer">
                            View your certificate
                          </a>
                        </div>
                      </div> */}
                    {/* )} */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {showModal && (
        <div className="modal show" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header d-flex">
                <h5 className="modal-title text-center w-100">
                  {alreadyDownloaded ? 'Already Downloaded!' : userExists ? 'Certificate Details!' : 'Error!'}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowModal(false)}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body text-center">
                {alreadyDownloaded ? (
                  <p>The certificate has already been downloaded by you. Kindly contact ColorGradeshala support.</p>
                ) : userExists ? (
                  <p>Congratulations! Your certificate is ready to download.</p>
                ) : (
                  <p>We could not find your details. Please try again or contact support.</p>
                )}
              </div>
              <div className="modal-footer justify-content-center">
                {userExists ? (
                  <button
                    type="button"
                    className="btn btn-style-two"
                    onClick={() => window.open(pdfLink, '_blank')}
                  >
                    Download Now
                  </button>
                ) : (
                  <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>
                    Close
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

    
 {/* <div className="modal" tabindex="-1">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Modal title</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <p>Modal body text goes here.</p>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div> */}
 
    <footer className="home-six-footer">
        <div className="footer-top footer-bg">
            <div className="newsletter-area s-newsletter-area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="newsletter-wrap">
                                <div className="section-title newsletter-title in-between">
                                    <h2> Facing any issue ? &nbsp;&nbsp; <span> <a href="tel:9711078793"><i className="fa fa-phone"></i> &nbsp; (+91) 9711078793</a> </span> &nbsp;&nbsp;OR&nbsp;&nbsp; <span> <a href="mailto:colorgradeshala@gmail.com"><i className="fa fa-envelope"></i> colorgradeshala@gmail.com</a> </span></h2>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="copyright-wrap s-copyright-wrap">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="copyright-text in-between">
                            <p>Copyright © 2024 | <a href="#">Colorgradeshala</a> | All Rights Reserved | Design By <b><span style={{"color":"white"}}> <a href="#" target="_blank">Silver Sparrow Production</a> </span></b></p>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
    </footer>
    </>
  )
}

export default Certificate;
